import {
  Component,
  createMemo,
  For,
  JSXElement,
  Show,
  useContext,
} from 'solid-js';
import {
  ActionDropdown,
  AppPage,
  AuthContext,
  Breadcrumbs,
  callDialog,
  FaIcon,
  Link,
  LocalApiContext,
  LocalizationContext,
  LocalWorkdir,
} from '../../components';
import { linkProject, linkProjectBranch, linkProjectBranchVersion, linkProjectBranchVersionPackage } from './links';
import { ConfirmDialog } from '../../dialogs/basic';

const Workdir: Component<{
  workdir: LocalWorkdir;
}> = (props) => {
  const { t } = useContext(LocalizationContext)!;
  const { callLocalApi, connected, workspaces, workdirs, refreshWorkdirs, transition } = useContext(LocalApiContext)!;

  const actions = createMemo(() => {
    const actions: {
      title: string;
      icon?: JSXElement;
      onAction: () => void;
      danger?: boolean;
    }[] = [];
    actions.push({
      title: t('local.workdir.action.delete'),
      icon: <FaIcon solid trash-can inline weak />,
      onAction: async () => {
        if(!await callDialog(ConfirmDialog, {
          header: t('local.workdir.delete_dialog.header'),
          message: t('local.workdir.delete_dialog.message')(props.workdir.package_title),
          ok: t('local.workdir.delete_dialog.ok'),
          danger: true,
        })) return;
        await callLocalApi('workdir_delete', {
          workdir: props.workdir.id,
          deleteFiles: true,
        });
        refreshWorkdirs();
      },
      danger: true,
    });
    return actions;
  });

  return <>
    <div>
      <Show when={props.workdir.package_id != null}>
        <Breadcrumbs breadcrumbs={[
          <Link href={linkProject(props.workdir.project_id!)}>{props.workdir.project_title}</Link>,
          ...(props.workdir.branch_id ? [<Link href={linkProjectBranch(props.workdir.project_id!, props.workdir.branch_id)}>{props.workdir.branch_title}</Link>] : []),
          ...(props.workdir.version_id ? [<Link href={linkProjectBranchVersion({
            projectId: props.workdir.project_id!,
            branchId: props.workdir.branch_id,
            versionId: props.workdir.version_id,
          })}>{props.workdir.version_title}</Link>] : []),
          <Link href={linkProjectBranchVersionPackage({
            projectId: props.workdir.project_id!,
            branchId: props.workdir.branch_id,
            versionId: props.workdir.version_id,
            packageId: props.workdir.package_id!,
          })}>{props.workdir.package_title}</Link>,
        ]} />
      </Show>
      <Show when={props.workdir?.package_id === transition()?.workdir?.package_id}>
        <progress max={transition()?.total} value={transition()?.done} />
      </Show>
      <div class="buttons group">
        <ActionDropdown actions={actions()} />
      </div>
      <a href={`file://${props.workdir.local_path}`} class="local_path" onClick={async (e) => {
        e.preventDefault();
        e.stopPropagation();
        await callLocalApi('workdir_show_folder', props.workdir.id);
      }}><FaIcon regular folder inline weak />{props.workdir.local_path}</a>
    </div>
  </>;
};

export const RouteLocal: Component = () => {
  const { t } = useContext(LocalizationContext)!;
  const { api } = useContext(AuthContext)!;
  const { callLocalApi, connected, workspaces, workdirs, refreshWorkdirs, transition } = useContext(LocalApiContext)!;

  return <>
    <AppPage
      title={t('route.local')}
      breadcrumb={t('route.local')}
      uiClass="local"
    />
    <h1>{t('route.local')}</h1>
    <Show when={connected()} fallback={
      <div class="highlight warning"><p>{t('local.app_not_detected.message')}</p></div>
    }>
      <h2>{t('local.workdirs')}</h2>
      <div class="list workdirs">
        <For each={workdirs()}>{(workdir) => <Workdir workdir={workdir} />}</For>
      </div>
      <h2>{t('local.workspaces')}</h2>
      <div class="list workspaces">
        <For each={workspaces()}>{(workspace) =>
          <div>
            <div class="local_path">{workspace.local_path}</div>
          </div>
        }</For>
      </div>
    </Show>
    <div class="footer">
    </div>
  </>;
};
