export const linkHome = '/';
export const linkProjects = '/projects';
export const linkProject = (projectId: string) => `${linkProjects}/${encodeURIComponent(projectId)}`;
export const linkProjectBranch = (projectId: string, branchId: string) => `${linkProject(projectId)}/branches/${encodeURIComponent(branchId)}`;
export const linkProjectBranchVersion = ({ projectId, branchId, versionId }: {
  projectId: string;
  branchId?: string;
  versionId: string;
}) => `${branchId ? linkProjectBranch(projectId, branchId) : linkProject(projectId)}/versions/${encodeURIComponent(versionId)}`;
export const linkProjectBranchVersionPackage = ({ projectId, branchId, versionId, packageId } : {
  projectId: string;
  branchId?: string;
  versionId?: string;
  packageId: string;
}) => `${versionId ? linkProjectBranchVersion({
  projectId,
  branchId,
  versionId,
}) : linkProject(projectId)}/packages/${encodeURIComponent(packageId)}`;
export const linkProjectBranchVersionPackagePlay = ({ projectId, branchId, versionId, packageId } : {
  projectId: string;
  branchId?: string;
  versionId?: string;
  packageId: string;
}) => `${linkProjectBranchVersionPackage({
  projectId,
  branchId,
  versionId,
  packageId,
})}/play`;
export const linkProjectBranchVersionPackageCloudPlay = ({ projectId, branchId, versionId, packageId } : {
  projectId: string;
  branchId?: string;
  versionId?: string;
  packageId: string;
}) => `${linkProjectBranchVersionPackage({
  projectId,
  branchId,
  versionId,
  packageId,
})}/cloud-play`;
export const linkProjectManage = (projectId: string) => `${linkProject(projectId)}/manage`;
export const linkStore = '/store';
export const linkStoreProject = (projectId: string) => `${linkStore}/${encodeURIComponent(projectId)}`;
export const linkStoreProjectBranchPackagePlay = ({ projectId, branchId, packageId } : {
  projectId: string;
  branchId: string;
  packageId: string;
}) => `${linkStoreProject(projectId)}/${branchId}/${packageId}/play`;
export const linkMe = '/me';
export const linkLocal = '/local';
export const linkInvite = (token: string) => `/invite?token=${encodeURIComponent(token)}`;
export const linkDocs = (lang: string) => `/docs/${encodeURIComponent(lang)}/`;
