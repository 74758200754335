const dict = {
  languageName: 'Русский',
  locale: 'ru',
  fallback: 'en',
  dir: 'ltr',

  // app
  'app.name': 'Кавыхи.Cloud',
  'app.title': (sub) => `${sub} - ${dict['app.name']}`,

  // themes
  theme: 'Тема',
  'theme.light': 'Светлая',
  'theme.dark': 'Тёмная',
  'theme.system': 'Системная',

  // generic
  email: 'Email',
  password: 'Пароль',
  ok: 'OK',
  cancel: 'Отмена',
  retry: 'Повторить',
  copy: 'Копировать',
  empty: '(пусто)',
  loading: 'Загрузка...',
  calculating: 'Вычисление...',
  error: 'Ошибка',

  'in_development': 'Эта страница в разработке.',

  // errors
  'error.not_found': 'не найдено',
  'error.name_conflict': 'конфликт имён',
  'error.bad_param': 'неправильный параметр',
  'error.wrong_auth': 'неправильные данные авторизации',
  'error.unauthorized': 'нет прав',
  'error.wrong_credentials': 'неверные данные авторизации',
  'error.wrong_provider': 'неверная платформа авторизации',
  'error.insufficient_privileges': 'недостаточно привилегий',
  'error.not_empty': 'не пустой',
  'error.limit_exceeded': 'лимит превышен',
  'error.already_member': 'уже в команде',
  'error.not_finalized': 'не финализирован',
  'error.already_finalized': 'уже финализирован',
  'error.unexpected_patch': 'неожиданный патч',
  'error.timeout': 'таймаут',
  'error.not_patch': 'не патч',
  'error.not_supported': 'не поддерживается',
  'error.unexpected_error': 'неизвестная ошибка',

  // data sizes
  'datasize.B': 'Б',
  'datasize.KiB': 'КиБ',
  'datasize.MiB': 'МиБ',
  'datasize.GiB': 'ГиБ',
  'datasize.TiB': 'ТиБ',

  // auth providers
  'auth.provider.steam': 'Steam',
  'auth.provider.github': 'GitHub',
  'auth.provider.gitlab': 'GitLab',
  'auth.provider.itch': 'itch.io',
  'auth.provider.twitch': 'Twitch',
  'auth.provider.discord': 'Discord',

  // auth dialog
  'auth.dialog.header': 'Авторизация',
  'auth.dialog.please_select': 'Выберите тип аккаунта для входа.',
  'auth.dialog.redirecting_to': (provider) => `Авторизация через ${provider}...`,
  'auth.dialog.remember_me': 'Запомнить меня',
  'auth.dialog.log_in': 'Вход',
  'auth.dialog.back': 'Назад',
  'auth.dialog.progress_header': 'Авторизация...',

  'user.profile': 'Профиль',
  'user.logout': 'Выход',

  // deploy credential providers
  'deploy_credential_provider.steam': 'Steam',
  'deploy_credential_provider.itch': 'itch.io',

  'page_not_found.header': 'Страница не найдена',
  'page_not_found.message': 'Этой страницы не существует.',
  'page_not_found.message2': 'Ну ладно, существует, но тут ничего нет. Возможные причины:',
  'page_not_found.items': [
    'Что-то было здесь раньше, но теперь это удалено.',
    'Что-то всё ещё здесь, но вы не имеете доступа.',
    'Или просто кто-то неаккуратно скопировал ссылку.',
  ],

  'route.home': 'Начало',
  'home.header.hint': 'Облако для инди разработчиков',
  'home.auth': 'Войти или зарегистрироваться',

  'route.projects': 'Проекты',
  'route.projects.hint': 'Проекты, над которыми вы работаете',
  'projects.project': (project) => `Проект: ${project}`,
  'projects.project.hint': 'проект',
  'projects.no_projects': 'У вас пока нет проектов.',
  'projects.new': 'Создать проект',
  'projects.new_dialog.header': 'Новый проект',
  'projects.new_dialog.label': 'Название проекта',
  'projects.new_dialog.ok': 'Создать проект',
  'projects.store_page': 'Публичная страница проекта',
  'projects.description': 'Описание',
  'projects.branches': 'Ветки',
  'projects.versions': 'Версии',
  'projects.packages': 'Пакеты',
  'projects.management': 'Управление проектом',
  'projects.management.breadcrumb': 'Управление',
  'projects.management.title': (projectTitle) => `${projectTitle} - управление`,
  'projects.management.hint': 'управление проектом',
  'projects.management.users': 'Команда',
  'projects.management.users.empty': 'В команде никого нет.',
  'projects.management.users.role.tester': 'Тестировщик',
  'projects.management.users.role.developer': 'Разработчик',
  'projects.management.users.role.admin': 'Администратор',
  'projects.management.users.role.owner': 'Владелец',
  'projects.management.users.exile': 'Исключить из команды',
  'projects.management.users.exile.dialog.header': 'Исключение из команды',
  'projects.management.users.exile.dialog.message': (projectTitle, userTitle) => <>Вы уверены, что хотите исключить <strong>{userTitle}</strong> из проекта <strong>{projectTitle}</strong>?</>,
  'projects.management.users.exile.dialog.ok': 'Исключить из команды',
  'projects.management.invites.one_time_invite': 'Одноразовая ссылка для приглашения',
  'projects.management.invites.one_time_invite.header': 'Одноразовая ссылка для приглашения',
  'projects.management.invites.one_time_invite.message': 'Одноразовая ссылка для приглашения:',
  'projects.management.invites.one_time_invite.note': 'Будет работать 24 часа, может быть использована только один раз.',
  'projects.management.invites.new_dialog.header': 'Ссылка для приглашения',
  'projects.management.invites.new_dialog.expires': 'Активна:',
  'projects.management.invites.new_dialog.expires.indefinite': 'бессрочно',
  'projects.management.invites.new_dialog.approval.label': 'Вступление в проект:',
  'projects.management.invites.new_dialog.approval.not_approved': 'после одобрения администратором',
  'projects.management.invites.new_dialog.approval.approved': 'сразу',
  'projects.management.invites.new_dialog.seats': 'Ограничить количество:',
  'projects.management.invites.new_dialog.seats.no_limit': '(без ограничения)',
  'projects.management.invites.new_dialog.ok': 'Создать ссылку',
  'projects.management.deploy_sites': 'Платформы для публикации',
  'projects.management.deploy_sites.empty': 'Нет добавленных платформ для публикации.',
  'projects.management.deploy_sites.add': 'Добавить платформу',
  'projects.management.deploy_sites.delete': 'Удалить',
  'projects.management.deploy_sites.delete_dialog.header': 'Удаление платформы',
  'projects.management.deploy_sites.delete_dialog.message': (deploySite) => <>Вы уверены, что хотите удалить цель <strong>{deploySite.site}</strong> на <strong>{dict[`deploy_credential_provider.${deploySite.provider}`]}</strong>?</>,
  'projects.management.deploy_sites.delete_dialog.ok': 'Удалить платформу',
  'projects.management.public_access': 'Публичный доступ',
  'projects.management.public_access.enable': 'Разрешить публичный доступ',
  'projects.management.public_access.enabled': <>Публичный доступ: <strong>разрешён</strong></>,
  'projects.management.public_access.enabled.note': 'Название и описание проекта доступны публично, как и ветки, которые вы сделали публичными.',
  'projects.management.public_access.enable_dialog.header': 'Разрешение публичного доступа',
  'projects.management.public_access.enable_dialog.message': (projectTitle) => <>Вы уверены, что хотите разрешить публичный доступ к проекту <strong>{projectTitle}</strong>?<br /><br />Это сделает основную информацию о проекте доступной без авторизации. Вам нужно будет отдельно разрешить публичный доступ для каждой ветки, которую вы хотите сделать таковой.</>,
  'projects.management.public_access.enable_dialog.ok': 'Разрешить публичный доступ',
  'projects.management.public_access.disable': 'Запретить публичный доступ',
  'projects.management.public_access.disabled': <>Публичный доступ: <strong>запрещён</strong></>,
  'projects.management.public_access.disabled.note': 'Проект приватный. Только команда проекта имеет к нему доступ.',
  'projects.management.public_access.disable_dialog.header': 'Запрещение публичного доступа',
  'projects.management.public_access.disable_dialog.message': (projectTitle) => <>Вы уверены, что хотите запретить публичный доступ к проекту <strong>{projectTitle}</strong>?</>,
  'projects.management.public_access.disable_dialog.ok': 'Запретить публичный доступ',
  'projects.management.public_access.branches': 'Публичные ветки',
  'projects.management.manage': 'Управление проектом',
  'projects.rename': 'Переименовать проект',
  'projects.rename_dialog.header': 'Переименование проекта',
  'projects.rename_dialog.label': 'Имя проекта:',
  'projects.rename_dialog.ok': 'Переименовать проект',
  'projects.edit_description': 'Изменить описание проекта',
  'projects.edit_description_dialog.header': 'Изменение описания проекта',
  'projects.edit_description_dialog.label': 'Описание проекта:',
  'projects.edit_description_dialog.ok': 'Изменить описание',
  'projects.leave': 'Покинуть проект',
  'projects.leave_dialog.header': 'Выход из проекта',
  'projects.leave_dialog.message': (projectTitle) => <>Вы уверены, что хотите покинуть проект <strong>{projectTitle}</strong>?</>,
  'projects.leave_dialog.ok': 'Покинуть проект',
  'projects.delete': 'Удалить проект',
  'projects.delete_dialog.header': 'Удаление проекта',
  'projects.delete_dialog.message': (projectTitle) => <>Вы уверены, что хотите удалить проект <strong>{projectTitle}</strong>?</>,
  'projects.delete_dialog.ok': 'Удалить проект',

  'branches.header': (projectTitle, branchTitle) => `${projectTitle}: ${branchTitle}`,
  'branches.header.hint': 'ветка',
  'branches.no_branches': 'В проекте пока нет веток.',
  'branches.new': 'Создать ветку',
  'branches.new_dialog.header': 'Новая ветка',
  'branches.new_dialog.label': 'Название ветки',
  'branches.new_dialog.ok': 'Создать ветку',
  'branches.current_version': (version) => <>Текущая версия: {version}</>,
  'branches.no_current_version': 'Нет опубликованной версии',
  'branches.publish_version.new': 'Запостить новую версию',
  'branches.publish_version.existing': 'Перепостить существующую версию',
  'branches.version_history': 'История версий',
  'branches.version_history.empty': 'История версий ветки пуста.',
  'branches.version_history.note_removal': ({ quotaCount, quotaNonCurrentTime, quotaTime }) => <>Текущая версия ветки хранится {quotaTime != null ? <strong>{quotaTime}</strong> : <>бессрочно</>}. Архивные версии хранятся не более <strong>{quotaNonCurrentTime}</strong>. Всего для ветки хранится не более {plural(quotaCount, <><strong>{quotaCount}</strong> версии</>, <><strong>{quotaCount}</strong> версий</>, <><strong>{quotaCount}</strong> версий</>)}.</>,
  'branches.manage': 'Управление веткой',
  'branches.rename': 'Переименовать ветку',
  'branches.rename_dialog.header': 'Переименование ветки',
  'branches.rename_dialog.label': 'Название ветки:',
  'branches.rename_dialog.ok': 'Переименовать ветку',
  'branches.delete': 'Удалить ветку',
  'branches.delete_dialog.header': 'Удаление ветки',
  'branches.delete_dialog.message': (branchTitle) => <>Вы уверены, что хотите удалить ветку <strong>{branchTitle}</strong>?</>,
  'branches.delete_dialog.ok': 'Удалить ветку',

  'versions.header': (projectTitle, versionTitle) => `${projectTitle} ${versionTitle}`,
  'versions.header.hint': 'версия',
  'versions.current': 'Актуальная',
  'versions.previous': 'Архивная',
  'versions.deploy_to_site.header': 'Публикация на платформе',
  'versions.deploy_to_site': 'Опубликовать на платформе',
  'versions.packages': 'Пакеты',
  'versions.branches': 'В ветках',
  'versions.branches.publish': 'Перепостить в ветку',
  'versions.branches.empty': 'Версия не постилась ни в одной ветке.',
  'versions.new_dialog.header': 'Новая версия',
  'versions.new_dialog.label_title': 'Версия:',
  'versions.new_dialog.label_branch': 'Ветка:',
  'versions.new_dialog.packages.header': 'Пакеты',
  'versions.new_dialog.packages.empty': 'Нет добавленных пакетов.',
  'versions.new_dialog.packages.add': 'Добавить пакет',
  'versions.new_dialog.packages.add_existing': 'Добавить уже загруженный пакет',
  'versions.new_dialog.packages.replace': 'Заменить пакет',
  'versions.new_dialog.packages.edit': 'Изменить пакет',
  'versions.new_dialog.ok': 'Создать версию',
  'versions.manage': 'Управление версией',
  'versions.rename': 'Переименовать версию',
  'versions.rename_dialog.header': 'Переименование версии',
  'versions.rename_dialog.label': 'Название версии:',
  'versions.rename_dialog.ok': 'Переименовать версию',
  'versions.delete': 'Удалить версию',
  'versions.delete_dialog.header': 'Удаление версии',
  'versions.delete_dialog.message': (versionTitle) => <>Вы уверены, что хотите удалить версию <strong>{versionTitle}</strong>?</>,
  'versions.delete_dialog.ok': 'Удалить версию',

  'versions.publish_dialog.header': 'Перепостить версию',
  'versions.publish_dialog.message': (versionTitle) => <>Выберите ветку, чтобы перепостить версию <strong>{versionTitle}</strong>.</>,
  'versions.publish_dialog.no_branches': 'В проекте нет веток.',
  'versions.publish_dialog.ok': 'Перепостить версию',

  'versions.publish_existing_dialog.header': 'Перепост версии',
  'versions.publish_existing_dialog.message': 'Недавние версии:',
  'versions.publish_existing_dialog.no_versions': 'В проекте нет версий.',
  'versions.publish_existing_dialog.ok': 'Запостить версию',

  'packages.header': (projectTitle, packageTitle) => `${projectTitle}: ${packageTitle}`,
  'packages.header.hint': 'Пакет',
  'packages.download_zip': 'Скачать в ZIP',
  'packages.download_torrent': 'Скачать торрент',
  'packages.total_size': (size) => <>Всего объём: {size}</>,
  'packages.manage': 'Управление пакетом',
  'packages.rename': 'Переименовать пакет',
  'packages.rename_dialog.header': 'Переименование пакета',
  'packages.rename_dialog.label': 'Имя пакета:',
  'packages.rename_dialog.ok': 'Переименовать пакет',
  'packages.edit_tags': 'Изменить теги пакета',
  'packages.edit_tags_dialog.header': 'Изменение тегов пакета',
  'packages.edit_tags_dialog.message': (packageTitle) => <>Укажите теги для пакета <strong>{packageTitle}</strong>:</>,
  'packages.edit_tags_dialog.ok': 'Указать теги пакета',
  'packages.delete': 'Удалить пакет',
  'packages.delete_dialog.header': 'Удаление пакета',
  'packages.delete_dialog.message': (packageTitle) => <>Вы уверены, что хотите удалить пакет <strong>{packageTitle}</strong>? Пакет будет удалён из всех версий, в которые он был добавлен.</>,
  'packages.delete_dialog.ok': 'Удалить пакет',

  'packages.new_dialog.header': 'Новый пакет',
  'packages.new_dialog.label_title': 'Название:',
  'packages.new_dialog.title_placeholder': '(из тегов)',
  'packages.new_dialog.tags.header': 'Теги',
  'packages.new_dialog.files.header': 'Файлы',
  'packages.new_dialog.total_size': (size, maximum) => <>Всего объём: {size} {maximum}</>,
  'packages.new_dialog.total_size.maximum': (size) => <>(максимум {size})</>,
  'packages.new_dialog.new_data_size': (size) => <>Объём новых данных: {size}</>,
  'packages.new_dialog.checks.single_root_dir': 'Рекомендуется класть файлы непосредственно в корень, а не иметь единственную папку.',
  'packages.new_dialog.checks.single_root_dir.action': 'Заменить корневую папку содержимым',
  'packages.new_dialog.checks.index_html': <>В корне есть <code>index.html</code>. Добавьте тег Web, чтобы пакет можно было запускать в браузере.</>,
  'packages.new_dialog.checks.index_html.action': <>Добавить тег Web</>,

  'packages.add_existing_dialog.header': 'Добавить пакет',
  'packages.add_existing_dialog.add': (packagesCount) => packagesCount > 0 ? `Добавить ${plural(packagesCount, `${packagesCount} пакет`, `${packagesCount} пакета`, `${packagesCount} пакетов`)}` : 'Пакеты не выбраны',
  'packages.add_existing_dialog.message': 'Последние загруженные пакеты:',
  'packages.add_existing_dialog.no_packages': 'Нет недавно загруженных пакетов.',

  'packages.launch': 'Запустить',

  'packages.install': 'Установить',
  'packages.install_dialog.header': 'Установка',
  'packages.install_dialog.workdir.label': 'Папка:',
  'packages.install_dialog.workdir.value.new': '(создать новую)',
  'packages.install_dialog.workspace.label': 'Создать папку в:',
  'packages.install_dialog.workspace.placeholder': '(выберите корневую папку)',
  'packages.install_dialog.ok': 'Установить',

  'packages.update': 'Обновить',

  'packages.upload.status.existing': 'Из предыдущей версии',
  'packages.upload.status.queued': 'В очереди',
  'packages.upload.status.packing': 'Упаковка...',
  'packages.upload.status.creating': 'Создание...',
  'packages.upload.status.create_retrying': 'Ошибка создания, повтор после паузы',
  'packages.upload.status.splitting': 'Нарезка...',
  'packages.upload.status.uploading': 'Загрузка...',
  'packages.upload.status.finalizing': 'Завершение...',
  'packages.upload.status.finalize_retrying': 'Ошибка завершения, повтор после паузы',
  'packages.upload.status.resolving': 'Обработка патча...',
  'packages.upload.status.resolve_retrying': 'Ошибка обработки патча, повтор после паузы...',
  'packages.upload.status.done': 'Успешно загружено',
  'packages.upload.status.errored': 'Ошибка',

  'packages.upload.part.status.queued': 'В очереди',
  'packages.upload.part.status.hashing': 'Хеширование',
  'packages.upload.part.status.upload_queued': 'В очереди на загрузку',
  'packages.upload.part.status.uploading': 'Загрузка',
  'packages.upload.part.status.upload_retrying': 'Ошибка, повторная загрузка',
  'packages.upload.part.status.upload_finalizing': 'Завершение загрузки',
  'packages.upload.part.status.upload_errored': 'Ошибка',
  'packages.upload.part.status.done': 'Загружено',

  'packages.play': 'Играть в браузере',
  'packages.play.reset': 'Перезапустить',
  'packages.play.fullscreen': 'На полный экран',

  'packages.cloud_play': 'Играть в облаке',
  'packages.cloud_play.start': 'Начать играть в облаке',
  'packages.cloud_play.fullscreen': 'На полный экран',
  'packages.cloud_play.status.not_started': 'Не начали',
  'packages.cloud_play.status.creating_session': 'Создание сессии...',
  'packages.cloud_play.status.awaiting_machine': 'Ожидание машины...',
  'packages.cloud_play.status.connecting': 'Подключение...',
  'packages.cloud_play.status.playing': 'Игра',
  'packages.cloud_play.status.error': 'Ошибка',

  'deploy_sites.add_dialog.header': 'Добавление платформы',
  'deploy_sites.add_dialog.message': (projectTitle) => <>Выберите ключ и введите URL проекта на платформе, чтобы в дальнейшем публиковать проект <strong>{projectTitle}</strong>.</>,
  'deploy_sites.add_dialog.label_credential': 'Ключ публикации:',
  'deploy_sites.add_dialog.select_credential': 'Выберите ключ',
  'deploy_sites.add_dialog.select_credential.new': 'Добавить новый ключ...',
  'deploy_sites.add_dialog.label_select_site': 'Выберите сайт:',
  'deploy_sites.add_dialog.select_credential_first': '(сначала выберите ключ)',
  'deploy_sites.add_dialog.select_site': '(выберите сайт)',
  'deploy_sites.add_dialog.label_enter_site': 'Или введите URL:',
  'deploy_sites.add_dialog.ok': 'Добавить платформу',

  'deploy_to_site_dialog.header': 'Публикация на платформе',
  'deploy_to_site_dialog.message': (projectTitle, versionTitle) => <>Выберите платформу для публикации версии <strong>{versionTitle}</strong> проекта <strong>{projectTitle}</strong>.</>,
  'deploy_to_site_dialog.label_site': 'Платформа:',
  'deploy_to_site_dialog.select_site': 'Выберите платформу',
  'deploy_to_site_dialog.select_site.new': 'Добавить платформу публикации...',
  'deploy_to_site_dialog.packages.message.itch': <>Пакеты будут опубликованы на itch.io с помощью <a href="https://itch.io/docs/butler/pushing.html" target="_blank">butler</a>. Имена каналов формируются из тегов пакетов.</>,
  'deploy_to_site_dialog.deploy': 'Опубликовать',
  'deploy_to_site_dialog.close': 'Закрыть',
  'deploy_to_site_dialog.error.no_packages': 'Нет пакетов для публикации.',
  'deploy_to_site_dialog.error.non_unique_channels': (packagesCount, channel) => <>Нельзя публиковать несколько пакетов в один канал. Сейчас на публикацию в канал <strong>{channel}</strong> настроены {plural(packagesCount, <><strong>{packagesCount}</strong> пакет</>, <><strong>{packagesCount}</strong> пакета</>, <><strong>{packagesCount}</strong> пакетов</>)}.</>,
  'deploy_to_site_dialog.job.queued': 'В очереди',
  'deploy_to_site_dialog.job.downloading': 'Загрузка...',
  'deploy_to_site_dialog.job.unpacking': 'Распаковка...',
  'deploy_to_site_dialog.job.uploading': 'Публикация...',
  'deploy_to_site_dialog.job.succeeded': 'Успешно',
  'deploy_to_site_dialog.job.errored': 'Ошибка',

  'route.store': 'Витрина',
  'route.store.hint': 'Публичные проекты',
  'store.project.hint': 'Страница проекта',
  'store.project.owner': 'Разработчик',
  'store.project.description': 'Описание',
  'store.project.content': 'Контент',
  'store.project.content.warning': 'Контент предоставлен сторонним разработчиком.',
  'store.project.branch_version': (branchTitle, versionTitle) => <>Ветка <strong>{branchTitle}</strong>: версия <strong>{versionTitle}</strong></>,
  'store.project.branch_version.published': (branchVersionPublished) => <>Опубликовано: <strong>{branchVersionPublished}</strong></>,

  'route.me': 'Профиль',
  'route.me.hint': 'Настройки профиля и привязанные аккаунты',
  'me.rename': 'Переименовать профиль',
  'me.rename_dialog.header': 'Переименование профиля',
  'me.rename_dialog.label_title': 'Имя:',
  'me.rename_dialog.ok': 'Переименовать',
  'me.credentials': 'Привязанные аккаунты',
  'me.credentials.hint': 'Любой из привязанных аккаунтов может быть использован для входа в текущий аккаунт.',
  'me.credentials.empty': 'Нет привязанных аккаунтов.',
  'me.credentials.profile_picture.alt': (provider) => `Картинка профиля ${dict[`auth.provider.${provider}`]}`,
  'me.credentials.created': (created) => <>добавлен {created}</>,
  'me.credentials.last_used': (lastUsed) => <>вход {lastUsed}</>,
  'me.credential.unlink': 'Отвязать',
  'me.credential.link_another': 'Привязать ещё',
  'me.credential.link_dialog.header': 'Привязка аккаунта',
  'me.credential.link_dialog.message': 'Выберите тип аккаунта для привязки.',
  'me.credential.unlink_dialog.header': 'Отвязка аккаунта',
  'me.credential.unlink_dialog.are_you_sure': (credential) => <>Вы уверены, что хотите отвязать аккаунт <strong>{credential.title}</strong> на <strong>{dict[`auth.provider.${credential.provider}`]}</strong> от вашего аккаунта {dict['app.name']}?<br /><br />Вы больше не сможете использовать его для входа.</>,
  'me.credential.unlink_dialog.ok': 'Отвязать аккаунт',
  'me.sessions': 'Сессии',
  'me.sessions.hint': 'Это список ваших активных сессий, включая текущий браузер.',
  'me.sessions.started': (started) => <>создана <span class="value">{started}</span></>,
  'me.sessions.last_auth': (lastAuth) => <>активность <span class="value">{lastAuth}</span></>,
  'me.sessions.current': 'Текущая сессия',
  'me.sessions.empty': 'Нет сессий.',
  'me.sessions.delete': 'Завершить',
  'me.sessions.delete_dialog.header': 'Завершение сессии',
  'me.sessions.delete_dialog.message': 'Вы уверены, что хотите завершить эту сессию?',
  'me.sessions.delete_dialog.ok': 'Завершить сессию',
  'me.api_tokens': 'API токены',
  'me.api_tokens.hint': 'Здесь можно сгенерировать API токены, чтобы использовать их для автоматизации действий с облаком.',
  'me.api_tokens.started': (started) => <>создан <span class="value">{started}</span></>,
  'me.api_tokens.expires': (expires) => <>протухнет <span class="value">{expires}</span></>,
  'me.api_tokens.no_expiration': 'вечный',
  'me.api_tokens.empty': 'Нет API токенов.',
  'me.api_tokens.create': 'Создать API токен',
  'me.api_tokens.create_dialog.header': 'Создание API токена',
  'me.api_tokens.create_dialog.message': 'API токен создан:',
  'me.api_tokens.create_dialog.note': 'Токен нельзя будет посмотреть ещё раз, сохраните его сейчас.',
  'me.api_tokens.delete': 'Удалить',
  'me.api_tokens.delete_dialog.header': 'Удаление API токена',
  'me.api_tokens.delete_dialog.message': 'Вы уверены, что хотите удалить этот API токен?',
  'me.api_tokens.delete_dialog.ok': 'Удалить API токен',
  'me.deploy_credentials': 'Ключи для публикации',
  'me.deploy_credentials.hint': 'Здесь можно добавить ключи для публикации на сторонних платформах.',
  'me.deploy_credentials.empty': 'Нет добавленных ключей для публикации.',
  'me.deploy_credential.add': 'Добавить',
  'me.deploy_credential.add_dialog.header': 'Добавить ключ',
  'me.deploy_credential.add_dialog.label_site': 'Сайт:',
  'me.deploy_credential.add_dialog.label_title': 'Примечание:',
  'me.deploy_credential.add_dialog.label_key': 'Ключ:',
  'me.deploy_credential.add_dialog.default_title': (date) => `Ключ ${date}`,
  'me.deploy_credential.add_dialog.ok': 'Добавить ключ',
  'me.deploy_credential.add_dialog.hint.steam': <>Добавление ключа Steam для публикации пока не поддерживается.</>,
  'me.deploy_credential.add_dialog.hint.itch': <>Чтобы сгенерировать API ключ itch.io, зайдите в <a href="https://itch.io/user/settings/api-keys" target="_blank">настройки профиля</a> на itch.io. Если уже есть API ключ с источником "web" или "wharf", можно взять его. Ключ с источником "Kavykhi.Cloud" здесь не подходит.</>,
  'me.deploy_credential.delete': 'Удалить',
  'me.deploy_credential.delete_dialog.header': 'Удаление ключа',
  'me.deploy_credential.delete_dialog.message': (credential) => <>Вы уверены, что хотите удалить ключ <strong>{credential.title}</strong> для <strong>{dict[`deploy_credential_provider.${credential.provider}`]}</strong>? Платформы, использующие этот ключ, будут также удалены из проекта.</>,
  'me.deploy_credential.delete_dialog.ok': 'Удалить ключ',
  'me.public_profile': 'Публичный профиль',
  'me.public_profile.public_credentials': 'Публичные аккаунты',
  'me.public_profile.public_credentials.hint': 'Выберите аккаунты, которые будут публично отображаться.',
  'me.manage': 'Управление аккаунтом',
  'me.delete': 'Удалить аккаунт',
  'me.delete.dialog.header': 'Удаление аккаунта',
  'me.delete.dialog.message': (userTitle) => <>Вы уверены, что хотите безвозвратно удалить аккаунт {dict['app.name']} <strong>{userTitle}</strong>?</>,
  'me.delete.dialog.ok': 'Удалить аккаунт',

  'route.invite': 'Приглашение',
  'route.invite.hint': 'Приглашение в проект',
  'invite.message.already_member': (projectTitle) => <>Вы уже в команде проекта <strong>{projectTitle}</strong>.</>,
  'invite.message.join': (projectTitle) => <>Вы приглашены в проект <strong>{projectTitle}</strong>.</>,
  'invite.join': 'Присоединиться к проекту',

  'route.local': 'Локальный контент',
  'route.local.hint': 'Локально установленный контент',
  'local.workdirs': 'Установленные пакеты',
  'local.workdir.action.launch': 'Запустить',
  'local.workdir.action.repair': 'Исправить',
  'local.workdir.action.delete': 'Удалить',
  'local.workdir.delete_dialog.header': 'Удаление пакета',
  'local.workdir.delete_dialog.message': (packageTitle) => <>Вы уверены, что хотите удалить пакет <strong>{packageTitle}</strong> с этого устройства?</>,
  'local.workdir.delete_dialog.ok': 'Удалить',
  'local.workspaces': 'Корневые папки',
  'local.app_not_detected.message': 'Локальное приложение Кавых не обнаружено. Пожалуйста, скачайте и установите его по одной из ссылок внизу. Если приложение уже установлено, убедитесь, что оно запущено.',

  'local_app.detected': 'Локальный контент',

  'error_dialog.header': 'Ошибка',
  'error_dialog.messageFunc': (e) => `Ошибка: ${dict[`error.${e}`] ?? e}.`,

  'upload_box.choose_files': 'Выбрать файлы',
  'upload_box.choose_directories': 'Выбрать папки',
  'upload_box.add.progress_dialog.header': 'Добавление файлов',
  'upload_box.add.progress_dialog.message': 'Обработка файлов...',
  'upload_box.delete': (itemsCount) => `Удалить ${plural(itemsCount, `${itemsCount} файл`, `${itemsCount} файла`, `${itemsCount} файлов`)}`,
  'upload_box.start_upload': 'Начать загрузку',
  'upload_box.drop_area.message': 'Перетащите файлы и папки сюда',
  'upload_box.drop_area.box_dropping_message': 'Отпустите здесь, чтобы положить в корень',
  'upload_box.entry.explode_folder': 'Заменить папку её содержимым',
  'upload_box.entry.mark_executable': 'Пометить исполняемым',
  'upload_box.entry.unmark_executable': 'Убрать исполняемость',
  'upload_box.entry.remove': 'Убрать',

  'package.tags.empty': '(нет тегов)',
  'package.tag.os': 'ОС',
  'package.tag.os:windows': 'Windows',
  'package.tag.os:linux': 'Linux',
  'package.tag.os:macos': 'macOS',
  'package.tag.os:android': 'Android',
  'package.tag.os:ios': 'iOS',
  'package.tag.os:web': 'Web',
  'package.tag.arch': 'Архитектура',
  'package.tag.arch:x86': 'x86',
  'package.tag.arch:arm': 'ARM',
  'package.tag.bits': 'Битность',
  'package.tag.bits:64': '64 бита',
  'package.tag.bits:32': '32 бита',

  'route.docs': 'Документация',
  'docs.page_no_language': 'Недоступно на этом языке',
  'docs.page_no_language.message': 'Доступны другие версии этого документа:',
  'docs.page_no_language.other_link': (doc, lang) => <>({lang}) {doc}</>,

  'social_links.discord': 'Discord',

  'report_content': 'Пожаловаться на контент',
  'report_content.dialog.header': 'Жалоба на контент',
  'report_content.dialog.link': 'Отправить жалобу по email',
  'report_content.dialog.message': (email, link) => <>Нажмите на ссылку, чтобы отправить жалобу:<br /><br />{link}<br /><br />Или отправьте письмо вручную на <strong>{email}</strong></>,
};

export default dict;

const plural = (n: number, one: string, few: string, many: string) => {
  if(Math.floor(n / 10) % 10 == 1) return many;
  n %= 10;
  if(n == 0 || n >= 5) return many;
  if(n == 1) return one;
  return few;
};
