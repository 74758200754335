import {
  Accessor,
  Component,
  JSXElement,
  Resource,
  createContext,
  createMemo,
  createResource,
} from "solid-js";

export type LocalWorkspace = {
  id: number;
  local_path: string;
};
export type LocalWorkdir = {
  id: number;
  local_path: string;
  transitioning: boolean;
  project_id?: string;
  branch_id?: string;
  version_id?: string;
  package_id?: string;
  project_title?: string;
  branch_title?: string;
  version_title?: string;
  package_title?: string;
};
export type LocalTransition = {
  workdir: LocalWorkdir;
  stage: number;
  done: number;
  total: number;
};

export const LocalApiContext = createContext<{
  callLocalApi: (method: string, body?: any) => Promise<any>;
  connected: Accessor<boolean>;
  refreshConnected: () => void;
  workspaces: Resource<LocalWorkspace[]>;
  workdirs: Resource<LocalWorkdir[]>;
  refreshWorkdirs: () => void;
  transition: Resource<LocalTransition | null>;
  refreshTransition: () => void;
}>();
export const LocalApiProvider: Component<{
  children: JSXElement;
}> = (props) => {
  const callLocalApi = async (method: string, body: any = {}) => {
    const response = await fetch(`http://127.0.0.1:17303/?${new URLSearchParams({
      method,
    })}`, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'content-type': 'application/json',
      },
    });
    const responseBody = await response.json();
    if(response.ok) return responseBody;
    else throw responseBody;
  };

  const [version, { refetch: refreshConnected }] = createResource(async () => {
    try {
      return await callLocalApi('version') as string;
    } catch(e) {
    }
  });

  const connected = createMemo(() => version() != null);

  const [workspaces] = createResource(connected, async (connected) => {
    if(!connected) return [];
    return await callLocalApi('workspace_list') as LocalWorkspace[];
  });
  const [workdirs, { refetch: refreshWorkdirs }] = createResource(connected, async (connected) => {
    if(!connected) return [];
    return await callLocalApi('workdir_list') as LocalWorkdir[];
  });

  const [transition, { refetch: refreshTransition }] = createResource(connected, async () => {
    if(!connected) return null;
    const transition = await callLocalApi('transition_status') as LocalTransition | null;
    if(transition) {
      setTimeout(refreshTransition, 1000);
    }
    return transition;
  });

  return <LocalApiContext.Provider value={{
    callLocalApi,
    connected,
    refreshConnected,
    workspaces,
    workdirs,
    refreshWorkdirs,
    transition,
    refreshTransition,
  }}>{props.children}</LocalApiContext.Provider>;
};
