import {
  Component,
  createEffect,
  createSignal,
  Show,
  useContext,
} from 'solid-js';
import {
  AuthContext,
  Button,
  DialogParams,
  Dropdown,
  LocalApiContext,
  LocalizationContext,
  LocalWorkdir,
  LocalWorkspace,
} from '../components';
import {
  BranchInfo,
  PackageInfo,
  ProjectInfo,
  VersionInfo,
} from '../components/api-generated';

export const InstallPackageDialog: Component<DialogParams<{
  workdir?: LocalWorkdir;
  workspace?: LocalWorkspace;
}> & {
  project: ProjectInfo;
  branch?: BranchInfo;
  version: VersionInfo;
  pkg: PackageInfo;
}> = (props) => {
  const { t } = useContext(LocalizationContext)!;
  const { session } = useContext(AuthContext)!;
  const { callLocalApi, workspaces, workdirs } = useContext(LocalApiContext)!;

  const [selectedWorkspace, setSelectedWorkspace] = createSignal<LocalWorkspace | undefined>();
  createEffect(async () => {
    const userId = session()?.userInfo?.id;
    if(!userId) return;
    const workspaceId = Number.parseInt(localStorage.getItem('last_install_workspace_id') ?? '-1');
    const workspacesList = workspaces() ?? [];
    const workspace = workspacesList[workspaceId] ?? workspacesList[0];
    if(selectedWorkspace()) return;
    setSelectedWorkspace(workspace);
  });

  const [selectedWorkdir, setSelectedWorkdir] = createSignal<LocalWorkdir | undefined>();

  const workdirTitle = (workdir?: LocalWorkdir) => workdir
    ? `${workdir.project_title} - ${workdir.branch_title} - ${workdir.version_title} - ${workdir.package_title}`
    : t('packages.install_dialog.workdir.value.new')
  ;

  return (
    <props.dialog class="sheet ui big install_package">
      <h1>{t('packages.install_dialog.header')}</h1>
      <label>
        <div class="label">{t('packages.install_dialog.workdir.label')}</div>
        <div class="input">
          <Dropdown items={[
            {
              key: undefined,
              title: t('packages.install_dialog.workdir.value.new'),
            },
            ...(workdirs() ?? []).map((workdir) => ({
              key: workdir,
              title: workdirTitle(workdir),
            })),
          ]} title={workdirTitle(selectedWorkdir())} onSelect={setSelectedWorkdir} />
        </div>
      </label>
      <Show when={!selectedWorkdir()}>
        <label>
          <div class="label">{t('packages.install_dialog.workspace.label')}</div>
          <div class="input">
            <Dropdown items={(workspaces() ?? []).map((workspace) => ({
              key: workspace,
              title: workspace.local_path,
            }))} title={selectedWorkspace()?.local_path ?? t('packages.install_dialog.workspace.placeholder')} onSelect={setSelectedWorkspace} />
          </div>
        </label>
      </Show>
      <div class="footer">
        <div class="buttons align_end">
          <Button onClick={() => {
            const selectedWorkdirValue = selectedWorkdir();
            const selectedWorkspaceValue = selectedWorkspace();
            if(!selectedWorkdirValue && selectedWorkspaceValue) {
              localStorage.setItem('last_install_workspace_id', selectedWorkspaceValue.id.toString());
            }
            props.resolve({
              workdir: selectedWorkdirValue,
              workspace: selectedWorkspaceValue,
            });
          }} disabled={!selectedWorkdir() && !selectedWorkspace()}>{t('packages.install_dialog.ok')}</Button>
          <Button onClick={() => props.resolve()}>{t('cancel')}</Button>
        </div>
      </div>
    </props.dialog>
  );
};
