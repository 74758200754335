import {
  Component,
  createMemo,
  JSXElement,
  useContext,
} from 'solid-js';
import {
  linkProjectBranchVersionPackageCloudPlay,
  linkProjectBranchVersionPackagePlay,
} from '../app/routes/links';
import { AuthContext, LocalApiContext, LocalWorkdir } from '../components';
import config from '../config';
import { showErrorScope } from '../dialogs/basic';
import { InstallPackageDialog } from '../dialogs/install_package';
import { BranchInfo, PackageInfo, ProjectInfo, VersionInfo } from './api-generated';
import {
  ActionDropdown,
  callDialog,
  FaIcon,
} from './basic';
import { LocalizationContext } from './localization';
import { useRoutingNavigate } from './routing';

type Action = {
  title: string;
  icon?: JSXElement;
  onAction: () => void;
};

export const PackageActionButton: Component<{
  project: ProjectInfo;
  branch?: BranchInfo;
  version?: VersionInfo;
  pkg: PackageInfo;
}> = (props) => {
  const { api, session } = useContext(AuthContext)!;
  const { connected, callLocalApi, workdirs, refreshWorkdirs, refreshTransition } = useContext(LocalApiContext)!;
  const { t } = useContext(LocalizationContext)!;
  const navigate = useRoutingNavigate();

  const actions = createMemo(() => {
    const actions: Action[] = [];
    if(props.pkg.tags.indexOf('os:web') >= 0) {
      actions.push({
        title: t('packages.play'),
        icon: <FaIcon solid play weak inline />,
        onAction: () => {
          navigate(linkProjectBranchVersionPackagePlay({
            projectId: props.project.id,
            branchId: props.branch?.id,
            versionId: props.version?.id,
            packageId: props.pkg.id,
          }));
        },
      });
    }
    if(connected()) {
      actions.push({
        title: t('packages.install'),
        icon: <FaIcon solid cloud-arrow-down weak inline />,
        onAction: () => showErrorScope(async () => {
          const result = await callDialog(InstallPackageDialog, {
            project: props.project,
            branch: props.branch,
            version: props.version,
            pkg: props.pkg,
          });
          if(!result) return;

          const packageInfo = await api.getProjectsPackages1({
            project: props.project.id,
            _package: props.pkg.id,
          });

          const packageUrl = new URL(await api.getProjectsPackagesDownload({
            project: props.project.id,
            _package: props.pkg.id,
          }), config.storageBaseUrl);

          let workdirId = result.workdir?.id;
          if(!workdirId) {
            workdirId = await callLocalApi('workdir_add', {
              workspace: result.workspace!.id,
              name: props.project.id,
            });
          }
          await callLocalApi('workdir_transition', {
            id: workdirId,
            package_url: packageUrl,
            project_id: props.project.id,
            branch_id: props.branch?.id,
            version_id: props.version?.id,
            package_id: props.pkg.id,
            project_title: props.project.title,
            branch_title: props.branch?.title,
            version_title: props.version?.title,
            package_title: props.pkg.title,
            package_size: packageInfo.size,
          });
          refreshWorkdirs();
          refreshTransition();
        }),
      });
    }
    if((session()?.userPrivileges ?? []).indexOf('cloud_play') >= 0 && props.pkg.tags.indexOf('os:linux') >= 0 && props.pkg.tags.indexOf('arch:x86') >= 0) {
      actions.push({
        title: t('packages.cloud_play'),
        icon: <FaIcon solid play weak inline />,
        onAction: () => {
          navigate(linkProjectBranchVersionPackageCloudPlay({
            projectId: props.project.id,
            branchId: props.branch?.id,
            versionId: props.version?.id,
            packageId: props.pkg.id,
          }));
        },
      });
    }
    actions.push({
      title: t('packages.download_zip'),
      icon: <FaIcon solid file-zipper weak inline />,
      onAction: () => showErrorScope(async () => {
        const url = new URL(await api.getProjectsPackagesDownload({
          project: props.project.id,
          _package: props.pkg.id,
        }), config.storageBaseUrl);
        url.searchParams.set('filter', 'zip');
        const link = document.createElement('a');
        link.href = url.toString();
        link.download = `${props.project.title} ${props.pkg.title}.zip`;
        link.click();
      }),
    });
    actions.push({
      title: t('packages.download_torrent'),
      icon: <FaIcon solid magnet weak inline />,
      onAction: () => showErrorScope(async () => {
        const url = new URL(await api.getProjectsPackagesTorrent({
          project: props.project.id,
          _package: props.pkg.id,
        }), config.storageBaseUrl);
        const link = document.createElement('a');
        link.href = url.toString();
        link.download = `${props.project.title} ${props.pkg.title}.torrent`;
        link.click();
      }),
    });
    return actions;
  });

  return <ActionDropdown actions={actions()} />;
};
