import {
  Set as ISet,
} from 'immutable';
import {
  Accessor,
  Component,
  createMemo,
  For,
  JSXElement,
  Setter,
  Show,
  useContext,
} from 'solid-js';
import {
  Dropdown,
  FaIcon,
} from './basic';
import {
  LocalizationContext,
} from './localization';

export const allTags: {
  [key: string]: {
    order: number;
    options: {
      [key: string]: {
        order: number;
        icon: any;
      },
    };
  };
} = (() => {
  const tags: any = {
    os: {
      options: {
        windows: {
          icon: {
            brands: true,
            windows: true,
          },
        },
        linux: {
          icon: {
            brands: true,
            linux: true,
          },
        },
        macos: {
          icon: {
            brands: true,
            apple: true,
          },
        },
        android: {
          icon: {
            brands: true,
            android: true,
          },
        },
        ios: {
          icon: {
            brands: true,
            apple: true,
          },
        },
        web: {
          icon: {
            brands: true,
            html5: true,
          },
        },
      },
    },
    arch: {
      options: {
        x86: {
          icon: {
            solid: true,
            microchip: true,
          },
        },
        arm: {
          icon: {
            solid: true,
            microchip: true,
          },
        },
      },
    },
    bits: {
      options: {
        '32': {
          icon: null,
        },
        '64': {
          icon: null,
        },
      },
    },
  };

  let i = 0;
  for(const tag in tags) {
    tags[tag].order = i++;
    let j = 0;
    for(const option in tags[tag].options) {
      tags[tag].options[option].order = j++;
    }
  }

  return tags;
})();

export const compareTags = (a: string, b: string) => {
  const aa = a.split(':');
  const bb = b.split(':');
  const ai = allTags[aa[0]].order;
  const bi = allTags[bb[0]].order;
  if(ai != bi) return ai - bi;
  return allTags[aa[0]].options[aa[1]].order - allTags[bb[0]].options[bb[1]].order;
};

export const getTagDesc = (tag: string) => {
  const [tagName, tagValue] = tag.split(':');
  return allTags[tagName].options[tagValue];
};

export const Tags: Component<{
  tags: ISet<string>;
  showIcons?: boolean;
  children?: JSXElement;
}> = (props) => {
  const { t } = useContext(LocalizationContext)!;

  return (
    <div class="tags">
      <For each={props.tags.toArray().sort(compareTags)} fallback={<div class="empty">{t('package.tags.empty')}</div>}>{(tag) => {
        const icon = createMemo(() => getTagDesc(tag).icon);
        return (
          <div>
            <Show when={(props.showIcons ?? true) && icon()}><FaIcon {...icon()} /></Show>
            <div>{t(`package.tag.${tag}`)}</div>
          </div>
        );
      }}</For>
      {props.children}
    </div>
  );
};

export const TagsEditor: Component<{
  tags: Accessor<ISet<string>>;
  setTags: Setter<ISet<string>>;
}> = (props) => {
  const { t } = useContext(LocalizationContext)!;

  const onTagSelect = (key: string) => {
    props.setTags((tags) => tags.has(key) ? tags.delete(key) : tags.add(key));
    return true;
  };

  return (
    <div class="buttons">{
      Object.entries(allTags).map(([tagName, tagDesc]) =>
        <Dropdown classList={{
          dropdown_tags: true,
        }} title={t(`package.tag.${tagName}`)} items={
          Object.entries(tagDesc.options).map(([tagOption, tagOptionDesc]) => ({
            key: `${tagName}:${tagOption}`,
            title:
              <div>
                <Show when={tagOptionDesc.icon}><FaIcon {...tagOptionDesc.icon} /></Show>
                {t(`package.tag.${tagName}:${tagOption}`)}
              </div>,
            icon: <FaIcon item_start inline regular {...{ [props.tags().has(`${tagName}:${tagOption}`) ? 'square-check' : 'square']: true, }} />,
          }))
        } onSelect={onTagSelect} />
      )
    }</div>
  );
};
