import {
  Accessor,
  Component,
  createContext,
  createEffect,
  createMemo,
  createSignal,
  Setter,
  Show,
  useContext,
} from 'solid-js';
import {
  Dropdown,
  FaIcon,
} from './basic';

export const LocalizationContext = createContext<{
  lang: Accessor<string>;
  setLang: Setter<string>;
  langs: Map<string, string>;
  dicts: {
    [lang: string]: any;
  };
  t: (key: string) => any;
}>();
export const LocalizationProvider: Component<{
  dicts: {[lang: string]: any;};
  defaultLang: string;
  children: any;
}> = (props) => {
  // detect lang initially from local storage, or browser language
  let initialLang = localStorage.getItem('lang') ?? window.navigator.language;
  // hackish try to simplify language if not found in dicts
  if(initialLang && !props.dicts[initialLang]) initialLang = initialLang.substring(0, 2);
  // fallback to en if needed
  if(!initialLang || !props.dicts[initialLang]) initialLang = props.defaultLang;

  // signal for current language
  const [lang, setLang] = createSignal(initialLang);

  // dict for current language
  const dict = createMemo(() => props.dicts[lang()]);

  const getText = (id: string, dict: any): any => dict[id] ?? (dict.fallback ? getText(id, props.dicts[dict.fallback]) : null);
  const t = (id: string) => getText(id, dict());

  // update current language in local storage
  createEffect(() => {
    localStorage.setItem('lang', lang());
    document.documentElement.lang = lang();
    document.documentElement.dir = t('dir');
  });

  return (
    <LocalizationContext.Provider value={{
      lang,
      setLang,
      langs: new Map(Object.keys(props.dicts).toSorted().map((lang) => [lang, props.dicts[lang].languageName])),
      dicts: props.dicts,
      t,
    }}>{
      props.children
    }</LocalizationContext.Provider>
  );
};

export const LocalizationSelector: Component = () => {
  const { lang: currentLang, langs, setLang } = useContext(LocalizationContext)!;
  return (
    <Dropdown title={<><FaIcon solid globe weak inline />{currentLang().toUpperCase()}</>} items={
      Array.from(langs.entries()).map(([lang, langName]) => ({
        key: lang,
        title: <span lang={lang}>{langName}</span>,
        icon: <FaIcon regular circle-dot={lang == currentLang()} circle={lang != currentLang()} inline />,
      }))
    } onSelect={setLang} />
  );
};
